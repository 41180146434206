.navText {
  color: white;
  margin-right: 20px;
  margin-top: 5px;
  margin-bottom: 0px;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
